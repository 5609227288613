<template>
  <div class="h-100">
		<div class="d-flex flex-column align-items-center justify-content-center h-100 mx-5">
			<div class="alert alert-success alert-dismissible fade show" role="alert" style="max-width: 30rem; width: 100%;" v-show="alertMessage != ''">
				<div v-html="alertMessage"></div>
				<button type="button" class="close" data-dismiss="alert" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			
			<div>
				<p class="text-center">
					Input Informasi Anda,<br/>
					Untuk mendapatkan Sertifikat Partisipasi Acara Anda.
				</p>
			</div>
			
			<div class="form-group" style="max-width: 30rem; width: 100%;">
				<label for="exampleInputName">Name</label>
				<input type="text" class="form-control" id="exampleInputName" v-model="name">
			</div>
			
			<div class="form-group" style="max-width: 30rem; width: 100%;">
				<label for="exampleInputEmail">Email</label>
				<input type="email" class="form-control" id="exampleInputEmail" v-model="email">
			</div>
			
			<div class="form-group" style="max-width: 30rem; width: 100%;">
				<label for="exampleInputWANumber">WA Number</label>
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text" id="basic-addon1">+62</span>
					</div>
					<input type="number" pattern="[0-9]*" inputmode="numeric" class="form-control" id="exampleInputWANumber" v-model="wa_number">
				</div>
			</div>
			
			<div class="form-group" style="max-width: 30rem; width: 100%;">
				<button type="submit" class="btn btn-primary w-100" @click="onSubmit">Submit</button>
				<a class="d-none" id="newTab" href="http://google.com" target="_blank">Submit</a>
			</div>
    </div>
		
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {  },
  data(){
    return{
      base: null,
			arr_factor: [],
			scrollY: 0,
			alertMessage: '',
			name: '',
			email: '',
			wa_number: '',
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY(){
      this.manage_start_animation()
    },
		wa_number(val){
			this.wa_number = this.base.phone_validation(val)
		}
  },
  async mounted(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
		this.arr_factor = [true,]
		
		var alertMessage = await window.sessionStorage.getItem('alertMessage')
		if(alertMessage != null){
			this.alertMessage = alertMessage
			await window.sessionStorage.removeItem('alertMessage')
		}
  },
  methods: {
		async onSubmit(){
			window.$('#newTab').click()
			
			if(this.name == '')
				this.base.show_error('Name is Empty')
			else if(this.email == '')
				this.base.show_error('Email is Empty')
			else if(this.wa_number == '')
				this.base.show_error('WA Number is Empty')
			else if(!this.base.validate_email(this.email))
				this.base.show_error('Email is not email format')
			else{
				window.$('#please_wait_modal').modal('show')
				
				var data = {}
				data.name = this.name
				data.email = this.email
				data.wa_number = '+62' + this.wa_number
				
				var response = await this.base.request(this.base.url_api + '/participant', 'post', data);
				
				window.$('#please_wait_modal').modal('hide')
				if (response != null) {
					if (response.status == "success") {
						window.sessionStorage.setItem('alertMessage', 'Submit Successfully!</br>To view certificate again, you can input your name again in this website')
						
						// window.$('#newTab').attr('href', this.base.host + '/export/certificate?id=' + response.data.id)
						
						window.location.href = this.base.host + '/export/certificate?id=' + response.data.id
						// window.open(this.base.host + '/export/certificate?id=' + response.data.id, '_blank')
						// window.location.reload()
					}
					else
						this.base.show_error(response.message)
				}
				else
					this.base.show_error('Server Error')
			}
		},
    handleScroll(){
      this.scrollY = window.scrollY
    },
    manage_start_animation(){
//       this.flag.homeBanner = this.base.check_start_animation(this.scrollY, this.flag.homeBanner, this.arr_factor, 0)
//       this.flag.homeSearch = this.base.check_start_animation(this.scrollY, this.flag.homeSearch, this.arr_factor, 0)
// 
//       var whyChooseImage = window.$('#home-search').innerHeight() - 600
//       this.flag.recommendationTitle1 = this.base.check_start_animation(this.scrollY, this.flag.recommendationTitle1, this.arr_factor, 200, whyChooseImage)
//       this.flag.recommendationTitle2 = this.base.check_start_animation(this.scrollY, this.flag.recommendationTitle2, this.arr_factor, 200, whyChooseImage)
// 
//       whyChooseImage += window.$('#recommendation').innerHeight() - 600
//       this.flag.whyChooseTitle1 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseTitle1, this.arr_factor, 1100, whyChooseImage)
//       this.flag.whyChooseTitle2 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseTitle2, this.arr_factor, 1100, whyChooseImage)
//       this.flag.whyChooseUs1 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseUs1, this.arr_factor, 1150, whyChooseImage)
//       this.flag.whyChooseUs2 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseUs2, this.arr_factor, 1200, whyChooseImage)
//       this.flag.whyChooseUs3 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseUs3, this.arr_factor, 1250, whyChooseImage)
//       this.flag.testimonyTitle1 = this.base.check_start_animation(this.scrollY, this.flag.testimonyTitle1, this.arr_factor, 1100, whyChooseImage)
//       this.flag.testimonyTitle2 = this.base.check_start_animation(this.scrollY, this.flag.testimonyTitle2, this.arr_factor, 1100, whyChooseImage)
//       this.flag.articleTitle1 = this.base.check_start_animation(this.scrollY, this.flag.articleTitle1, this.arr_factor, 1500, whyChooseImage)
//       this.flag.articleTitle2 = this.base.check_start_animation(this.scrollY, this.flag.articleTitle2, this.arr_factor, 1500, whyChooseImage)
    },
  }
}
</script>

<style lang="scss">
.vertical{
  background-color: $gray2;
  width: 1px;
  height: 100%;
}
.slick-prev:before, .slick-next:before {
  background-color: $black !important;
}
.slick-dots {
  bottom: -5rem;
}
.slick-dots button:before {
  color: $gray8 !important; /* color of dots */
  font-size: .8rem !important;
  opacity: 1 !important;
}
.slick-dots .slick-active button:before {
  color: $primary !important;
  font-size: .8rem !important;
  opacity: 1 !important;
}
</style>

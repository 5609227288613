<template>
  <!-- <div class="row bg-login custom-navbar-padding-left custom-navbar-padding-right min-vh-100 pb-5 pb-md-0"> -->
  <div class="row bg-login p-5 justify-content-center position-relative">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style=" position: absolute; right: 0; top: 0;">
      <img src="@/assets/login_close.png">
    </button>
    <!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-center"> -->
    <div class="col-auto d-flex align-items-center justify-content-center">
      <img src="@/assets/logo_new.png" style="max-width: 240px;">
    </div>

    <div class="col-auto col-lg d-flex align-items-center justify-content-center position-relative">
      <!-- <a href="/" class="position-absolute" style="top: 1rem; right: 0rem;">
      </a> -->
      <!-- <div style="max-width: 25.6rem;"> -->
      <div style="max-width: 25.6rem;">
        <p class="m-0 text-primary login-title">Change Profile</p>

        <div class="form-group">
          <label for="oldPassword">Name</label>
          <input type="text" v-model="name" class="form-control">
        </div>

        <div class="form-group">
          <label for="oldPassword">Email</label>
          <input type="text" v-model="email" disabled class="form-control">
        </div>

        <div class="form-group">
          <label for="oldPassword">Profile Picture</label>
          <CustomImagePicker
            :image_data1="image_data"
            @onResponse="onImageResponse"/>
        </div>

        <div class="form-group">
          <label for="oldPassword">Phone</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">+62</span>
            </div>
            <input type="text" v-model="phone_number" class="form-control">
          </div>
        </div>

        <div class="form-group">
          <label for="newPassword">Address</label>
          <textarea v-model="address" class="form-control mb-3"></textarea>
        </div>

        <div class="form-group">
          <label for="confirmPassword">Province</label>
          <select v-model="province" class="form-control mb-3">
            <option value="">{{ $t('select_province') }}</option>
            <option v-for="(province, index) in arr_province" :key="index" :value="province.id">{{ province.name }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="confirmPassword">City</label>
          <select v-model="city" class="form-control mb-3">
            <option value="">{{ $t('select_city') }}</option>
            <option v-for="(city, index) in arr_city" :key="index" :value="city.id">{{ city.name }}</option>
          </select>
        </div>

        <div class="mt-3">
          <button class="btn btn-primary text-warning w-100" @click="onSubmitClicked">{{ $t('submit') }}</button>
          <!-- <p class="m-0 text-primary login-forget mt-3">By clicking on Sign up, you agree to our Terms of service and Privacy policy.</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'

import Base from '@/utils/base';
import NoImage from '@/assets/no_image.png';
import CustomImagePicker from '@/layout/custom_imagepicker';

export default {
  components: {
    'CustomImagePicker': CustomImagePicker,
  },
  data() {
    return {
      base: null,
      name: '',
      email: '',
      phone_number: '',
      arr_province: [],
      arr_city: [],
      province: '',
      city: '',
      address: '',
      image_data: {},
      profile_data: {},
    }
  },
  watch: {
    phone_number(val){
      this.phone_number = this.base.phone_validation(val)
    },
    province(val){
      if(val != '')
        this.get_city()
    },
  },
  mounted() {
    this.base = new Base()
    this.get_auth()
    this.get_province()
  },
  methods: {
    onImageResponse(image_data){
      this.image_data = image_data
    },
    async get_province(){
      var response = await this.base.request(this.base.url_api + "/province/all")

      if(response != null){
        if(response.status === "success"){
          this.arr_province = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_city(){
      var response = await this.base.request(this.base.url_api + "/city/all?province_id=" + this.province)

      if(response != null){
        if(response.status === "success"){
          this.arr_city = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_auth(){
      var response = await this.base.request(this.base.url_api + "/auth/profile")

      if(response != null){
        if(response.status == "success"){
          this.profile_data = response.data
          this.name = response.data.name
          this.email = response.data.email
          this.image_data = {
            image_display: response.data.file_name != null ? this.base.host + '/media/user?file_name=' + response.data.file_name : NoImage,
            image_data: null,
          }
          this.phone_number = response.data.phone.substring(3)
          this.address = response.data.address
          if(response.data.city != null){
            this.province = response.data.city.province.id
            this.city = response.data.city.id

            this.get_city()
          }
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async onSubmitClicked(){
      if(this.phone_number == "")
        this.base.show_error(this.$t('phone_number_empty'))
      else if(this.address == "")
        this.base.show_error(this.$t('address_empty'))
      else if(this.province == "")
        this.base.show_error(this.$t('province_empty'))
      else if(this.city == "")
        this.base.show_error(this.$t('city_empty'))
      else{
        var province = {}
        var city = {}
        var data = {}

        for(let province1 of this.arr_province){
          if(province1.id == this.province){
            province = province1
            break
          }
        }
        for(let city1 of this.arr_city){
          if(city1.id == this.city){
            city = city1
            break
          }
        }

        data = {
          name: this.name,
          phone: "+62" + this.phone_number,
          address: this.address,
          province: province,
          city: city,
        }

        if(this.image_data.data_image != null)
          data.image = {
            file: this.image_data.data_image,
          }
        console.log(data)

        $('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/auth/change-profile", 'post', data)
        $('#please_wait_modal').modal('hide')

        if(response != null){
          if(response.status === "success"){
            window.location.reload()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))

        
      }
    },
  }
}
</script>

<style lang="scss">
.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

html,
body,
body .fullscreen {
  height: 100%;
}

.bg-login {
  background-image: linear-gradient(to right, $primary , $yellow3);
}

.login-hr {
  width: 100%;
  height: 1px;
  border: 1px solid $primary;
}

.login-title {
  font-family: 'inter-medium';
  font-size: 2rem;
}

.login-detail {
  font-family: 'inter-regular';
}

.login-forget {
  font-family: 'inter-light';
}

.login-reset-password {
  font-family: 'inter-medium';
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}</style>

import { render, staticRenderFns } from "./forget_password_success.vue?vue&type=template&id=4c29f551&"
import script from "./forget_password_success.vue?vue&type=script&lang=js&"
export * from "./forget_password_success.vue?vue&type=script&lang=js&"
import style0 from "./forget_password_success.vue?vue&type=style&index=0&id=4c29f551&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
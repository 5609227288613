<template>
  <!-- <div class="row bg-login custom-navbar-padding-left custom-navbar-padding-right min-vh-100 pb-5 pb-md-0"> -->
  <div class="row bg-login p-5 justify-content-center position-relative">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style=" position: absolute; right: 0; top: 0;">
      <img src="@/assets/login_close.png">
    </button>
    <!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-center"> -->
    <div class="col-auto d-flex align-items-center justify-content-center">
      <img src="@/assets/logo_new.png" style="max-width: 240px;">
    </div>

    <div class="col-auto col-lg d-flex align-items-center justify-content-center position-relative">
      <!-- <a href="/" class="position-absolute" style="top: 1rem; right: 0rem;">
      </a> -->
      <!-- <div style="max-width: 25.6rem;"> -->
      <div style="max-width: 25.6rem;">
        <p class="m-0 text-primary login-title">Change Password</p>

        <div class="form-group">
          <label for="oldPassword">Old Password</label>
          <input type="password" v-model="old_password" class="form-control" id="oldPassword">
        </div>

        <div class="form-group">
          <label for="newPassword">New Password</label>
          <input type="password" v-model="new_password" class="form-control" id="newPassword">
        </div>

        <div class="form-group">
          <label for="confirmPassword">Confirm New Password</label>
          <input type="password" v-model="confirm_password" class="form-control" id="confirmPassword">
        </div>

        <div class="mt-3">
          <button class="btn btn-primary text-warning w-100" @click="onSubmitClicked">{{ $t('submit') }}</button>
          <!-- <p class="m-0 text-primary login-forget mt-3">By clicking on Sign up, you agree to our Terms of service and Privacy policy.</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'

import Base from '@/utils/base';

export default {
  components: {
  },
  data() {
    return {
      base: null,
      old_password: '',
      new_password: '',
      confirm_password: '',
    }
  },
  watch: {
    phone_number(val){
      this.phone_number = this.base.phone_validation(val)
    },
    province(val){
      if(val != '')
        this.get_city()
    },
  },
  created() {
    this.base = new Base()
  },
  methods: {
    async onSubmitClicked(){
      if(this.old_password == '')
        this.base.show_error('Old Password is Empty')
      else if(this.new_password == '')
        this.base.show_error('New Password is Empty')
      else if(this.confirm_password == '')
        this.base.show_error('Confirm Password is Empty')
      else if(this.new_password != this.confirm_password)
        this.base.show_error('Confirm Password is not Same with New Password')
      else{
        $('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/auth/change-password", "post", {
          old_password: this.old_password,
          new_password: this.new_password,
        })

        $('#please_wait_modal').modal('hide')
        if(response != null){
          if(response.status === "success"){
            window.location.reload()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
  }
}
</script>

<style lang="scss">
.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

html,
body,
body .fullscreen {
  height: 100%;
}

.bg-login {
  background-image: linear-gradient(to right, $primary , $yellow3);
}

.login-hr {
  width: 100%;
  height: 1px;
  border: 1px solid $primary;
}

.login-title {
  font-family: 'inter-medium';
  font-size: 2rem;
}

.login-detail {
  font-family: 'inter-regular';
}

.login-forget {
  font-family: 'inter-light';
}

.login-reset-password {
  font-family: 'inter-medium';
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}</style>
